import { FailureRes, Req, SuccessRes } from "../req";

export interface IShopifyGetThemesResponse {
  themes: ISmartrrShopTheme[];
}

type ThemeRole = "main" | "unpublished";

export interface ISmartrrShopTheme {
  created_at: string;
  id: number;
  name: string;
  previewable: boolean;
  processing: boolean;
  role: ThemeRole;
  theme_store_id: number;
  updated_at: string;
}

export async function getShopifyThemes(client: Req): Promise<FailureRes | SuccessRes<IShopifyGetThemesResponse>> {
  return client.getReq<IShopifyGetThemesResponse>("/themes");
}
